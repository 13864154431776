<template>
  <div class="project-interactive-site-plan-editor--copy-layer">
    <fd-button
      v-if="selectedLayer"
      class="copy-button"
      v-tooltip="'Duplicate Layer'"
      @click="copyLayerPolygon"
    >
      <i class="fas fa-clone"></i>
    </fd-button>
  </div>
</template>

<script>
import { mapGetters, mapState, mapMutations } from "vuex";
import { polygon } from "leaflet";
import {
  addFeatures,
  addListeners
} from "@/modules/Project/store/sitePlanEditor";
import { latLng } from "leaflet";

export default {
  components: {},
  mixins: [],
  props: {},
  data: function () {
    return {};
  },
  computed: {
    ...mapState({
      sitePlanMap: (state) => state.sitePlanEditor?.sitePlanMap
    }),
    ...mapGetters({
      selectedLayer: "sitePlanEditor/getSelectedLayer",
      selectedUnit: "sitePlanEditor/getSelectedLayerUnit"
    })
  },
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {
    ...mapMutations({
      selectLayer: "sitePlanEditor/selectLayer"
    }),
    shiftLatlng(latlng) {
      let shifted = latlng.map((latlng) => {
        return latLng(latlng.lat, latlng.lng + 10);
      });
      return shifted;
    },
    copyLayerPolygon() {
      let latlng = this.selectedLayer._latlngs;
      let offsetLatlng = this.shiftLatlng(latlng[0]);
      // Clone polygon & add to map
      let duplicatedPolygon = polygon(offsetLatlng, {}).addTo(this.sitePlanMap);
      // Add listener and init feature
      addFeatures(duplicatedPolygon);
      addListeners(duplicatedPolygon);
      // Select the cloned polygon
      duplicatedPolygon.fireEvent("click");
      // Pan to center of polygon
      let centerOfPolygon = duplicatedPolygon.getBounds().getCenter();
      this.sitePlanMap.panTo(centerOfPolygon);
    }
  }
};
</script>

<style lang="scss">
.project-interactive-site-plan-editor--copy-layer {
  .copy-button {
    $size: 45px;
    position: absolute;
    left: 10px;
    bottom: 10px;
    width: $size;
    height: $size;
    border: solid 1px #666;
    border-radius: 8px;
    background: white;
    color: #666;
    &:hover {
      background: #ddd;
      border: solid 1px #666;
      box-shadow: 0 0 5px #00000025;
    }
    i {
      font-size: 14px;
    }
  }
}
</style>
